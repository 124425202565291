// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-cats-js": () => import("./../../../src/pages/cats.js" /* webpackChunkName: "component---src-pages-cats-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-list-randomizer-js": () => import("./../../../src/pages/list-randomizer.js" /* webpackChunkName: "component---src-pages-list-randomizer-js" */),
  "component---src-pages-names-js": () => import("./../../../src/pages/names.js" /* webpackChunkName: "component---src-pages-names-js" */),
  "component---src-pages-numbers-js": () => import("./../../../src/pages/numbers.js" /* webpackChunkName: "component---src-pages-numbers-js" */),
  "component---src-reason-pages-cats-cats-page-bs-js": () => import("./../../../src/reason/pages/cats/CatsPage.bs.js" /* webpackChunkName: "component---src-reason-pages-cats-cats-page-bs-js" */),
  "component---src-reason-pages-index-page-bs-js": () => import("./../../../src/reason/pages/IndexPage.bs.js" /* webpackChunkName: "component---src-reason-pages-index-page-bs-js" */),
  "component---src-reason-pages-list-randomizer-list-randomizer-bs-js": () => import("./../../../src/reason/pages/ListRandomizer/ListRandomizer.bs.js" /* webpackChunkName: "component---src-reason-pages-list-randomizer-list-randomizer-bs-js" */),
  "component---src-reason-pages-names-page-bs-js": () => import("./../../../src/reason/pages/NamesPage.bs.js" /* webpackChunkName: "component---src-reason-pages-names-page-bs-js" */),
  "component---src-reason-pages-numbers-numbers-page-bs-js": () => import("./../../../src/reason/pages/numbers/NumbersPage.bs.js" /* webpackChunkName: "component---src-reason-pages-numbers-numbers-page-bs-js" */)
}

